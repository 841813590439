<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <h4 class="font-weight-semibold d-lg-none">Explore</h4>

        <div class="row">

            <!-- Sidebar nav -->
            <div class="col-lg-3">
                <aside class="sidebar mb-2 mb-sm-3 mb-sm-0 sticky-md-top">
                    <div class="sidebar-nav-select d-lg-none mb-sm-2">
                        <select @change="changeRoute($event)" class="form-select">
                            <option selected value="all">All Posts &#40;{{ counts.all }}&#41;</option>
                            <option value="explore">Surveys &#40;{{ counts.surveys }}&#41;</option>
                            <option value="shared">Shared With Me &#40;{{ counts.shared }}&#41;</option>
                          <option value="history">History &#40;{{ counts.history}}&#41;</option>
                        </select>
                    </div>
                    <ul class="sidebar-nav mb-3 mb-md-3 d-none d-lg-block">
                        <li class="sidebar-nav-item">
                            <router-link class="sidebar-nav-link" to="/surveys/all"
                                         :class="$route.path == '/surveys' ? 'active' :''">
                                All Posts
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ counts.all }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link class="sidebar-nav-link" to="/surveys/explore">
                                Surveys
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ counts.surveys }}</span>
                            </router-link>
                        </li>
                        <!--                        <li class="sidebar-nav-item">-->
                        <!--                            <a class="sidebar-nav-link" href="#">-->
                        <!--                                Discussions-->
                        <!--                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>-->
                        <!--                                <span v-else class="post-count">0</span>-->
                        <!--                            </a>-->
                        <!--                        </li>-->
                        <li class="sidebar-nav-item">
                            <router-link class="sidebar-nav-link" to="/surveys/shared">
                                Shared With Me
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ counts.shared }}</span>
                            </router-link>
                        </li>
                      <li class="sidebar-nav-item">
                        <router-link class="sidebar-nav-link" to="/surveys/history">
                          History
                          <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                          <span v-else class="post-count">{{ counts.history}}</span>
                        </router-link>
                      </li>
                    </ul>
                    <!-- Create post button -->
                    <b-button class="custom-btn custom-btn-success custom-btn-full" v-b-modal.create-post-modal>
                        <span class="d-none d-sm-inline-block">Create New Post</span>
                        <font-awesome-icon icon="plus" class="d-md-none d-flex w-auto m-auto"/>
                    </b-button>
                </aside>
            </div><!-- End column -->

            <div class="col-lg-9">

                <div class="row g-2 mb-2">
                    <div class="col-md-12 mb-sm-2 col-lg-6 mb-2 mb-sm-0">
                        <b-form-input v-model="filter_search" class="form-control d-inline-block"
                                      placeholder="Search by title or tags..." type="search"/>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="row g-2">
                            <div class="col-lg-7 col-md-6 filter-cats">
                                <multiselect
                                    v-model="filter_categories"
                                    :options="categories"
                                    :multiple="true"
                                    label="name"
                                    track-by="id"
                                    placeholder="All Categories"></multiselect>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <select class="form-select" v-model="filter_date">
                                    <option value="">Select Period</option>
                                    <option value="Last 1 Month">Last 1 Month</option>
                                    <option value="Last 3 Months">Last 3 Months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
              <div @change="changeRoute($event == history)" class="row">
                <main role="main" style="">
                  <span class="tag tag-javascript tag-md">#All</span>

                  <span class="tag tag-security tag-md">#Responded</span>

                  <span class="tag tag-firebase tag-md">#Shared</span>

                  <span class="tag tag-firestore tag-md">#Flagged</span>

                  <span class="tag tag-auth tag-md">#recent</span>

                  <span class="tag tag-auth tag-md">#category</span>

                </main>
              </div>

                <Alerts :_success="success" :_error="error"></Alerts>

                <div class="survey-list-container">

                    <!-- Spinner to display while posts are loading -->
                    <div v-if="wait" class="list-spinner d-flex justify-content-center mb-3">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>

                    <!-- Empty store shows if there's an error -->
                    <div v-if="!wait && error" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/error-message.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>{{ error }}</h4>
                            <h6>An error occurred while trying to fetch posts.</h6>
                        </div>
                    </div>

                    <div v-if="!wait && !error && surveys.data.length == 0" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/empty-box.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>No posts to display.</h4>
                            <h6>Try adjusting your search filter to find what you're looking for.</h6>
                        </div>
                    </div>

                    <ul class="survey-list">

                        <!-- Survey list item -->
                        <li v-for="survey in surveys.data" :key="survey.code" class="survey-list-item">
                            <h6>
                                <router-link :to="`/surveys/${survey.code}/${survey.slug}`">
                                    <font-awesome-icon :icon="survey.type == 'survey' ? 'poll-h' : 'comments'"
                                                       :title="`Post type: ${survey.type}`"/>
                                    {{ survey.title }}
                                </router-link>
                            </h6>
                            <p class="excerpt" :title="survey.shortDescription">
                                {{ survey.shortDescription }}
                            </p>
                            <div class="categories mb-3">
                                <b-badge v-for="c in survey.categories" :key="c.id">
                                    {{ c.name }}
                                </b-badge>
                            </div>
                            <p v-if="survey.tags" class="tags">
                                <span class="text-muted">Tags:</span> {{ survey.tags }}
                            </p>
                            <div class="survey-user ml-3">
                                <b-avatar variant="primary" size="35"
                                          :text="getFirstLetters(survey.user.name)"></b-avatar>
                                <a :href="'/user/' + survey.user_id"
                                   :title="'View all public surveys by ' + survey.user.name">
                                    {{ survey.user.name }}
                                    &#8226;
                                    <span class="datestamp">
                                            {{ moment(survey.created_at).format("MMM D, YYYY HH:mm") }}
                                        </span>
                                </a>
                            </div>
                            <div class="survey-list-item-actions">
                                <ul>
                                    <li>
                                        <span class="large-number">{{ survey.views }}</span>
                                        <span class="small-desc">views</span>
                                    </li>
                                    <li>
                                        <router-link :to="`/surveys/${survey.code}/${survey.slug}`"
                                                     class="text-decoration-none">
                                            <span class="large-number">{{ survey.questions_count }}</span>
                                            <span class="small-desc">questions</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <span class="large-number">{{ survey.respondents_count }}</span>
                                        <span class="small-desc">responses</span>
                                    </li>

                                    <!-- Dropdown menu -->
                                    <li>
                                        <b-dropdown right no-caret variant="link" :id="`dropdown-${survey.code}`"
                                                    toggle-class="text-decoration-none"
                                                    class="survey-list-item-options">
                                            <template #button-content>
                                                <font-awesome-icon icon="chevron-down"/>
                                            </template>
                                            <b-dropdown-item>
                                                <router-link
                                                    :to="'/surveys/'+ survey.code +'/'+ survey.slug"
                                                    title="Owner has disabled multiple responses from the same user">
                                                    Start survey
                                                </router-link>
                                            </b-dropdown-item>
<!--                                            <b-dropdown-item>-->
<!--                                                <router-link-->
<!--                                                    :to="`/surveys/ratesurvey/${survey.code}`">Rate this survey-->
<!--                                                </router-link>-->
<!--                                            </b-dropdown-item>-->
                                            <b-dropdown-item v-if="survey.visibility == 'public'">
                                                <a href="#" v-b-modal.share-modal @click="setShareSurvey(survey)">
                                                    Share
                                                </a>
                                            </b-dropdown-item>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-item>
                                                <router-link :to="`/surveys/flag/${survey.code}`">
                                                    Report this survey
                                                </router-link>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </li>

                                </ul>

                              <b-alert v-if="allowUnflagging(survey)" show variant="warning" class="mt-5">
                                <div>
                                    <h6 class="text-danger">You flagged this survey</h6>
                                  <button @click.prevent="unFlagSurvey(survey.code)"
                                          class=" right custom-btn custom-btn-sm custom-btn-primary mt-3 me-2">
                                    Unflag Survey
                                  </button>
                                </div>
                              </b-alert>
                            </div><!-- End actions/counts -->

                        </li><!-- End survey list item -->
                    </ul>
                </div><!-- End survey list container -->

                <!-- Pagination -->
                <Pagination
                    :limit="1"
                    :showDisabled="true"
                    :data="surveys"
                    @pagination-change-page="loadSurveys">
                </Pagination>


                <!-- Share Survey Modal -->
                <b-modal v-if="share_survey" size="lg" class="modal" id="share-modal" hide-footer hide-header centered>
                    <!-- Modal title -->
                    <template #modal-title>
                        <h4>Share Survey</h4>
                    </template>

                    <div>
                        <share-survey :code="share_survey.code"></share-survey>
                    </div>
                </b-modal>

            </div>
        </div>
    </div>
</template>
<script>
import Alerts from "../Helpers/Alerts"
import Pagination from 'laravel-vue-pagination'
import ShareSurvey from '../Profile/Survey/SurveyShare'
import Multiselect from "vue-multiselect";

export default {
    name: "Surveys",
    props: ['id', 'tab', 'auth'],
    components: {Alerts, Pagination, ShareSurvey, Multiselect},
    data() {
        return {
            survey: {},
            counts: {
                all: 0,
                surveys: 0,
                shared: 0,
                history: 0
            },
            surveys: {data: []},
            categories: [],
            share_survey: null,

            // Filters
            filter_search: '',
            filter_date: '',
            filter_categories: []
        }
    },
    mounted() {
        this.getCategories()
        this.loadSurveys()
        this.loadCounts()
    },
    watch: {
        $route() {
            this.loadSurveys()
        },
        filter_categories() {
            this.loadSurveys()
        },
        filter_date() {
            this.loadSurveys()
        },
        filter_search(a, b) {
            if (b.length >= 2) {
                this.loadSurveys()
            }
        },
    },
    methods: {
        allowUnflagging(survey) {
            return this.tab === 'history' && survey.isFlagged
        },
        changeRoute(event) {
            this.$router.push({path: '/surveys/' + event.target.value})
        },
        getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        },
        setShareSurvey(survey) {
            this.share_survey = survey
        },
        loadSurveys(page = 1) {
            window.scroll(0, 0)
            this.clear()
            this.wait = true
            let search = this.filter_search.replace('#', '')

            let category_ids = []
            this.filter_categories.forEach((i) => {
                category_ids.push(i.id)
            })

            let url = `/api/surveys/${this.tab}/?page=${page}&categories=` + category_ids + '&search=' + search + '&date=' + this.filter_date + '&pages=5'
            this.$http.get(url).then(response => {
                this.surveys = response.data.data
                this.loadCounts()
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
      unFlagSurvey(code) {
          this.$http.post(`/api/survey/unflag/${code}?user_id=${this.auth.id}`).then(() => {
            this.loadSurveys()
          })
       },
        loadCounts() {
            this.$http.get('/api/surveys/count').then(response => {
                this.counts = response.data.data
            })
        },
        getCategories() {
            this.$http.get('/api/categories').then(res => {
                this.categories = res.data.data
            })
        }
    }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>


.tag {
  display: inline-block;
  padding: 0.3em 0.85em;
  margin: 0.5em;
  border: 3px solid #1212;
  border-radius: 5em;

  transition: transform 0.2s;
  transition-timing-function: cubic-bezier(0.60, -0.85, 0.55, -0.60);
}
.tag:hover {
  cursor: pointer;
  background: #0b2559;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1.tag {
  margin-left: 0;
  margin-right: 0
}

.tag-md {
  font-size: .9em;
  display: inline-block;
  letter-spacing: .15ch;
  font-weight: 400
}

</style>